import { addLocaleData } from 'react-intl';

import 'moment/locale/ro';
// Use english language since antd is not translated into romanian.
import antdLocale from 'antd/lib/locale-provider/en_GB';
import intlLocale from 'react-intl/locale-data/ro';
import messages from './messages.po';

addLocaleData(intlLocale);

export default { antdLocale, intlLocale, messages };
